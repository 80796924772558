import { Link } from "react-router-dom";
import LandingTileComponent from "../components/landing-tile.component";
import { useLayoutContext } from "../providers/layout.provider";
import LinkComponent from "../components/link.component";

export default function LandingPage() {
  const { isMobile } = useLayoutContext();

  return (
    <div className="bg-purple">
      <div className="">
        <div className={`p-40 ${isMobile ? "divColumn" : "divFlex"}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <div>
              <span
                style={{ color: "white", fontSize: 45, fontWeight: "bold" }}
              >
                Smart decisions for a wealthier tomorrow
              </span>
              <div
                className="mt-40"
                style={{ color: "white", paddingRight: 20 }}
              >
                <span style={{ lineHeight: 1.5, fontSize: 20 }}>
                  Discover top features to enhance your business, personal
                  financial wellness and improve everyday productivity.
                </span>
              </div>

              <div
                style={{ justifyContent: "flex-start", display: "flex" }}
              ></div>
            </div>

            <div
              className="mt-40 divAlignItemsOnly"
              style={{ alignSelf: "baseline" }}
            >
              <Link to="/register" className="blue-button">
                Signup
              </Link>

              <div className="mx-20">
                <a style={{ color: "yellow" }} href="#products">
                  Learn More
                </a>
              </div>
            </div>
          </div>

          <img
            src="/assets/landing-page-1.png"
            width={isMobile ? "auto" : 600}
          />
        </div>

        <div
          className="divColumn divCenterAlign"
          style={{ backgroundColor: "white" }}
        >
          <LandingTileComponent />
        </div>

        <div className="my-0 p-40" style={{ backgroundColor: "white" }}>
          <GetTheAppItem isMobile={isMobile} />
        </div>
        <div className="my-0 p-40" style={{ backgroundColor: "white" }}>
          <LandingFooter />
        </div>
      </div>
    </div>
  );
}

function LandingFooter() {
  return (
    <div
      className="divFlex divCenterAlign"
      style={{
        backgroundColor: "var(--exhut-light-grey3)",
        padding: "20px 40px",
        gap: 10,
        fontSize: "small",
      }}
    >
      <div>
        <Link to="/about">About Us</Link>
      </div>
      <div>
        <span>|</span>
      </div>
      <div>
        <a href="https://help.expensehut.com/privacy" target="_blank">
          Privacy Policy
        </a>
      </div>
      <div>
        <span>|</span>
      </div>
      <div>
        <Link to="/careers">Careers</Link>
      </div>
    </div>
  );
}

function GetTheAppItem({ isMobile }: { isMobile?: boolean }) {
  if (isMobile) {
    return (
      <div
        className={"divCenterAlign divColumn my-20 p-20"}
        style={{ backgroundColor: "#DEEFFF", borderRadius: 30 }}
      >
        <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
          <span style={{ fontSize: 30, fontWeight: "bold" }}>Get the App</span>
          <div style={{ paddingRight: 32 }}>
            <p style={{ lineHeight: 1.3 }}>Available in Android and iOS</p>
          </div>

          <div>
            <AndroidAppLink />

            <div className="mx-10">
              <IOSAppLink />
            </div>
          </div>
        </div>

        <img
          style={{ width: 200 }}
          alt="exhut type-less"
          src={"/assets/iphone-exhut.png"}
        />
      </div>
    );
  }
  return (
    <div
      className={"divSpreadEven my-20"}
      style={{
        backgroundColor: "#DEEFFF",
        borderRadius: 30,
        padding: "100px 20px",
      }}
    >
      <img
        style={{ width: 200 }}
        alt="exhut type-less"
        src={"/assets/iphone-exhut.png"}
      />

      <div className={"mb-20"}>
        <span style={{ fontSize: 30, fontWeight: "bold" }}>Get the App</span>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>Available in Android and iOS</p>
        </div>

        <div>
          <AndroidAppLink />

          <div className="mx-10">
            <IOSAppLink />
          </div>
        </div>
      </div>
    </div>
  );
}

export function AndroidAppLink() {
  return (
    <div className="my-40">
      <a
        href="https://play.google.com/store/apps/details?id=com.snarain.expensehut"
        rel="noreferrer"
        target="_blank"
      >
        <img src="assets/google-play-badge.png" width="150px" />
      </a>
    </div>
  );
}

export function IOSAppLink() {
  return (
    <div className="mb-40">
      <a
        href="https://apps.apple.com/us/app/expense-hut/id1562719499"
        rel="noreferrer"
        target="_blank"
      >
        <img src="assets/apple-store-badge.svg" width="130px" />
      </a>
    </div>
  );
}
