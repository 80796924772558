import { Link } from "react-router-dom";
import "./careers.css";

export default function CareersSalesSpecialist() {
  return (
    <div className="body careers-body" style={{ margin: "0% 10%" }}>
      <div className="title">Careers</div>

      <div>
        <Link to="/careers">Back</Link>
      </div>

      <div className="divColumn my-80">
        <h2>Sales Specialist</h2>

        <div className="divSpread">
          <div>
            <strong>Remote</strong>
            <p>India, USA</p>
          </div>

          <div>Job ID: EH200122</div>
        </div>

        <div className="my-40">
          <strong>About Us</strong>
          <p>
            ExpenseHut is a digital platform designed to enhance businesses and
            individuals by boosting financial and everyday productivity. We are
            Austin based startup and we offer innovative and advanced solutions
            that address real-world challenges across industries like retail,
            restaurants, staffing, and content. Business by ExpenseHut is an
            all-in-one enterprise business software suite that includes POS with
            omnichannel payments, embedded payroll, bookkeeping software, and
            rewards. Our platform features tools tailored to businesses of all
            sizes, with the aim of helping users gain financial clarity and
            achieve lasting success. Join us in making smarter decisions today
            for a more prosperous tomorrow!
          </p>

          <div className="my-40">
            <strong>Overview</strong>

            <p>
              We are looking for a Sales Specialist to join our team and help us
              grow our customer base. The ideal candidate will have a passion
              for sales, a deep understanding of our products, and the ability
              to build strong relationships with clients. You will be
              responsible for identifying new business opportunities, managing
              customer relationships, and driving revenue growth. If you are a
              motivated self-starter with a proven track record in sales, we
              would love to hear from you.
            </p>
          </div>

          <div className="my-40" id="responsibilities">
            <strong>Responsibilities</strong>
            <ul className="my-20">
              <li>
                <strong>Client Acquisition & Retention:</strong>
                Identify and target potential clients, build relationships, and
                convert leads into customers. Maintain and grow relationships
                with existing clients to ensure satisfaction and retention.
              </li>
              <li>
                <strong>Sales Strategy & Execution:</strong>
                Develop and implement sales strategies to achieve revenue
                targets. Create and deliver compelling sales presentations to
                key stakeholders.
              </li>

              <li>
                <strong>Market Research:</strong>
                Conduct market research to identify trends, customer needs, and
                competitive landscape. Use insights to inform sales strategies
                and product development.
              </li>
              <li>
                <strong>Product Knowledge:</strong>
                Maintain a deep understanding of our products and services.
                Communicate product benefits and features effectively to
                potential clients.
              </li>
              <li>
                <strong>Sales Reporting:</strong>
                Track and report on sales performance metrics. Provide regular
                updates to management on sales activities, pipeline status, and
                revenue forecasts.
              </li>
              <li>
                <strong>Collaboration:</strong>
                Work closely with marketing, product, and customer support teams
                to ensure a seamless customer experience. Provide feedback to
                improve product offerings and sales processes.
              </li>
              <li>
                <strong>Customer Feedback:</strong>
                Gather and analyze customer feedback to identify areas for
                improvement. Use feedback to enhance customer satisfaction and
                drive product innovation.
              </li>
            </ul>
          </div>

          <div className="my-40">
            <strong>Qualifications</strong>
            <ul className="my-20">
              <li>
                Minimum 5 years of experience in sales, preferably in the
                technology or software industry.
              </li>
              <li>
                Proven track record of meeting or exceeding sales targets and
                driving revenue growth.
              </li>
              <li>
                Excellent communication, negotiation, and presentation skills.
              </li>
              <li>
                Strong understanding of market research techniques and data
                analysis.
              </li>
              <li>
                Ability to build and maintain strong client relationships and
                work collaboratively with cross-functional teams.
              </li>
            </ul>
          </div>

          <div className="my-40">
            <strong>Compensation & Benefits</strong>
            <p className="my-20">
              Compensation depends on country of residence and experience level.
              This job os open to candidates in India and USA.
            </p>
            <p>
              Compensation includes sales-based commission and may include
              standard base pay.
            </p>
          </div>

          <div className="my-40">
            <strong>How to Apply?</strong>
            <p className="my-20">
              Job applications are not your thing? No worries. We are more
              interested in your experience and skills than the application
              form.
              <p>
                Shoot us an email at <strong>jobs@expensehut.com</strong> with
                your resume attached. Please make sure to highlight your skills
                or include answers to the below questions to help us understand
                your experience better.
              </p>
              <ul>
                <li>
                  Have you been involved with strategizing sales for your
                  company? What was your involvement?
                </li>
                <li>
                  What is our take on B2B merchants avoiding to subscription to
                  new SaaS features due to increased cost and low ROI? How do
                  you plan to resolve this?{" "}
                </li>
                <li>
                  We believe in big dreams. What is your goal 5-10 years from
                  now?{" "}
                </li>
              </ul>
            </p>
          </div>

          <div className="my-40">
            <i>
              ExpenseHut is an Equal Employment Opportunity and, in the U.S., an
              Affirmative Action employer. All qualified applicants will receive
              consideration for employment without regard to unlawful
              consideration of race, color, religion, creed, national or ethnic
              origin, ancestry, place of birth, citizenship, sex, pregnancy /
              childbirth or related medical conditions, sexual orientation,
              gender identity or expression, marital or domestic partnership
              status, age, veteran or military status, physical or mental
              disability, medical condition, genetic information, political /
              organizational affiliation, status as a victim or family member of
              a victim of crime or abuse, or any other status protected by
              applicable law.
            </i>
          </div>
        </div>
      </div>
    </div>
  );
}
