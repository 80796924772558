import { Link } from "react-router-dom";
import "./careers.css";

export function CareersChiefTechnologyOfficer() {
  return (
    <div className="body careers-body" style={{ margin: "0% 10%" }}>
      <div className="title">Careers</div>

      <div>
        <Link to="/careers">Back</Link>
      </div>

      <div className="divColumn my-80">
        <h2>Chief Technology Officer</h2>

        <div className="divSpread">
          <div>
            <strong>Remote</strong>
            <p>India, USA</p>
          </div>

          <div>Job ID: EH100117</div>
        </div>

        <div className="my-40">
          <strong>About Us</strong>
          <p>
            ExpenseHut is a digital platform designed to enhance businesses and
            individuals by boosting financial and everyday productivity. We are
            an Austin-based startup, and we offer innovative and advanced
            solutions that address real-world challenges across industries like
            retail, restaurants, staffing, and content.
          </p>
          <p>
            Business, by ExpenseHut is an all-in-one enterprise business
            software suite that includes POS with omnichannel payments, embedded
            payroll, bookkeeping software, and rewards. Our platform features
            tools tailored to businesses of all sizes, with the aim of helping
            users gain financial clarity and achieve lasting success.
          </p>
          <p>
            Join us in making smarter decisions today for a more prosperous
            tomorrow!
          </p>

          <div className="my-40">
            <strong>Overview</strong>

            <p>
              We are looking for a Chief Technology Officer (CTO) to provide
              sound technical leadership in all aspects of our business. You
              will closely work with the CEO, stakeholders and customers to
              understand the gaps in B2B businesses and focus on delivering
              solutions to our merchants.
            </p>
          </div>

          <div className="my-40" id="responsibilities">
            <strong>Responsibilities</strong>
            <div>
              <p></p>
            </div>
            <ul className="my-20">
              <li className="my-20">
                <strong>Technology Strategy & Leadership:</strong>
                <div>
                  Develop and execute the company's technology roadmap in
                  alignment with business goals.
                </div>
              </li>

              <li className="line-20">
                <strong>Product Development & Innovation:</strong>
                <div>
                  Lead the engineering team in designing, developing, and
                  deploying scalable and secure technology solutions.
                </div>
              </li>
              <li className="my-20">
                <strong>Team Management & Growth:</strong>
                <div>
                  Build and manage a high-performing technology team, fostering
                  innovation and continuous improvement.
                </div>
              </li>
              <li className="my-20">
                <strong>Collaboration with Business Units:</strong>
                <div>
                  Work closely with other departments to align technological
                  advancements with operational and customer needs.
                </div>
              </li>
              <li className="my-20">
                <strong>Compliance & Risk Management:</strong>
                <div>
                  Ensure adherence to regulatory requirements and implement best
                  practices in data security and risk management.
                </div>
              </li>
              <li className="my-20">
                <strong>Vendor & Partnership Management:</strong>
                <div>
                  Oversee relationships with external technology vendors,
                  partners, and service providers.
                </div>
              </li>
              <li className="my-20">
                <strong>Technology Budgeting & Planning: </strong>
                <div>
                  Develop and manage the technology budget, ensuring optimal
                  resource allocation and cost control.
                </div>
              </li>
            </ul>
          </div>

          <div className="my-40">
            <strong>Minimum Qualifications</strong>
            <ul className="my-20">
              <li>
                At least 15+ years of progressive experience in software
                development.
              </li>
              <li>5+ years of experience leading engineering teams.</li>
              <li>
                Experience in full-stack engineering in at least one frontend,
                one backend, and cloud infrastructure.
              </li>
              <li>
                Experience in one or more programming languages - Javascript,
                Java and/or Python.
              </li>
              <li>
                Familiarity with cloud technologies and experience in building
                scalable and secure cloud-based solutions.
              </li>
              <li>
                Experience with at least two cloud providers - AWS, GCP, or
                Azure.{" "}
              </li>
              <li>
                Should have experience contributing to engineering directly
                mapping with product growth.
              </li>
              <li>
                Bachelors degree in Computer Science or related. Masters degree
                preferred.
              </li>
            </ul>
          </div>

          <div className="my-40">
            <strong>Preferred Qualifications</strong>
            <ul className="my-20">
              <li>Polyglot and experience in full stack applications.</li>
              <li>7+ years of experience leading engineering teams.</li>
              <li>
                Proven experience in scaling cloud services with high volume of
                traffic.
              </li>
              <li>Azure cloud and Azure devops.</li>
              <li>Masters degree or equivalent.</li>
            </ul>
          </div>

          <div className="my-40">
            <strong>Compensation & Benefits</strong>
            <p className="my-20">
              The United States targeted pay range for this position:
              <p>$80,000 to $180,000 per year</p>
              <p>with significant additional compensation in equity.</p>
              Our pay ranges reflect the minimum and maximum target for new hire
              pay for the full-time position determined by role, level, and
              location. Individual pay is based on additional factors including
              job-related skills, experience, and relevant education and/or
              training.
            </p>
          </div>

          <div className="my-40">
            <strong>How to Apply?</strong>
            <p className="my-20">
              Job applications are not your thing? No worries. We are more
              interested in your experience and skills than the application
              form.
              <p>
                Shoot us an email to <strong>jobs@expensehut.com</strong> with
                your resume attached. Please make sure to answer the following
                questions to help us understand your experience better.
              </p>
              <ul>
                <li>
                  How did you address an engineering bottleneck in your team and
                  what was the outcome?
                </li>
                <li>How did you think your background suits this role?</li>
                <li>
                  We believe in big dreams. What is your goal 5-10 years from
                  now?{" "}
                </li>
              </ul>
            </p>
          </div>

          <div className="my-40">
            <i>
              ExpenseHut is an Equal Employment Opportunity and, in the U.S., an
              Affirmative Action employer. All qualified applicants will receive
              consideration for employment without regard to unlawful
              consideration of race, color, religion, creed, national or ethnic
              origin, ancestry, place of birth, citizenship, sex, pregnancy /
              childbirth or related medical conditions, sexual orientation,
              gender identity or expression, marital or domestic partnership
              status, age, veteran or military status, physical or mental
              disability, medical condition, genetic information, political /
              organizational affiliation, status as a victim or family member of
              a victim of crime or abuse, or any other status protected by
              applicable law.
            </i>
          </div>
        </div>
      </div>
    </div>
  );
}
