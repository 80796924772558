import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { Divider } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./careers.css";

const Careers: React.FC = () => {
  return (
    <div className="body" style={{ margin: "0% 10%" }}>
      <div className="title">Careers</div>

      <div className="divColumn my-80">
        <h3>Sales</h3>
        <List sx={{ bgcolor: "background.paper" }}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <Link to="/careers/sales-specialist">Sales Specialist</Link>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: "text.primary", display: "inline" }}
                  >
                    Remote - USA, India
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>

          <div className="my-40">
            <Divider />
          </div>
        </List>
        <h3>Engineering</h3>
        <List sx={{ bgcolor: "background.paper" }}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <Link to="/careers/chief-technology-officer">
                  Chief Technology Officer
                </Link>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: "text.primary", display: "inline" }}
                  >
                    Remote - USA
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <div className="my-40">
            <Divider />
          </div>
        </List>
      </div>
    </div>
  );
};

export default Careers;
