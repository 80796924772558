import { BrowserRouter, Route, Routes } from "react-router-dom";
import FooterComponent from "./components/footer.component";
import MobileHeader from "./components/mobile.header.component";
import AboutPage from "./pages/about.page";
import ContactUs from "./pages/contact-us.page";
import ForgotPassword from "./pages/forgot-password.page";
import LandingPage from "./pages/landing.page";
import RegisterPage from "./pages/register.page";
import LoginPage from "./pages/login.page";
import ProductsPage from "./pages/products.page";
import { AuthenticatedRoutes } from "./routes";
import "./styles/button.styles.css";
import TermsConditionsComponent from "./components/other/TermsConditionsComponent";
import AuthProvider from "./providers/auth.provider";
import Careers from "./pages/careers/careers";
import CareersSalesSpecialist from "./pages/careers/sales-specialist";
import { CareersChiefTechnologyOfficer } from "./pages/careers/chief-technology-officer";

const Main = () => {
  return (
    <div className={`app`}>
      <BrowserRouter>
        {/*
        Do not move auth provider from here.
        The Mobile header component needs this.
        */}
        <AuthProvider>
          <MobileHeader />

          <Routes>
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/careers" element={<Careers />} />
            <Route
              path="/careers/sales-specialist"
              element={<CareersSalesSpecialist />}
            />
            <Route
              path="/careers/chief-technology-officer"
              element={<CareersChiefTechnologyOfficer />}
            />

            <Route path="/careers" element={<Careers />} />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register/verify" element={<RegisterPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<AuthenticatedRoutes />} />
          </Routes>
        </AuthProvider>

        <TermsConditionsComponent />
        <FooterComponent />
      </BrowserRouter>
    </div>
  );
};

export default Main;
