import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import { useLayoutContext } from "../providers/layout.provider";

export default function LandingTileComponent() {
  const containerCSS: any =
    window.innerWidth < 800
      ? { display: "flex", margin: "0px", flexDirection: "column" }
      : { display: "flex", margin: "40px" };
  return (
    <div id="products" className="my-80">
      <div style={containerCSS}>
        <WelcomeSliderTileComponent
          titleComponent={{
            url: "https://business.expensehut.com",
            text: "Small Businesses",
          }}
          imgSize={70}
          img={"/assets/image.png"}
          desc={
            "Cloud based POS, embedded payroll, invoice, rewards and employee management."
          }
        />

        <WelcomeSliderTileComponent
          titleComponent={{
            url: "https://app.expensehut.com",
            text: "Individuals",
          }}
          img={"/assets/bestway3.svg"}
          desc={
            "Track and manage your daily budget as individuals or families."
          }
          imgSize={100}
        />
      </div>

      <div style={containerCSS}>
        <WelcomeSliderTileComponent
          titleComponent={{
            url: "https://recruit.expensehut.com",
            text: "Job Search",
          }}
          img={"/assets/icons/search.png"}
          titleBackgroundColor={"var(--exhut-primary-light)"}
          subtitle={"Personal Finance Dashboard"}
          desc={"Hunt jobs efficiently with reviews and referrals."}
        />

        <WelcomeSliderTileComponent
          img={"/assets/icons/wallet.svg"}
          titleComponent={{
            url: "https://wallet.expensehut.com",
            text: "Wallet",
          }}
          titleBackgroundColor={"var(--exhut-light-red)"}
          desc={"Easily split bills with friends."}
        />
      </div>

      <div style={containerCSS}>
        <WelcomeSliderTileComponent
          titleComponent={{
            url: "https://www.blogs.expensehut.com",
            text: "Blogs",
          }}
          img={"/assets/icons/world-wide-web.svg"}
          titleBackgroundColor={"lightsalmon"}
          desc={"Publish your articles for free. No fees."}
        />

        <WelcomeSliderTileComponent
          titleComponent={{
            url: "https://hub.expensehut.com",
            text: "Hub",
          }}
          img={"/assets/network-hub.png"}
          titleBackgroundColor={"cornflowerblue"}
          desc={"Centralized portal for all ExpenseHut data."}
        />
      </div>
    </div>
  );
}

function WelcomeSliderTileComponent({
  titleComponent,
  img,
  desc,
  imgSize,
}: any) {
  const { isMobile } = useLayoutContext();

  return (
    <Slide
      direction="right"
      in={true}
      mountOnEnter
      unmountOnExit
      timeout={{
        appear: 1000,
        enter: 3000,
        // exit: 5000
      }}
    >
      <Paper
        elevation={3}
        sx={{
          margin: "20px 25px",
          height: "auto",
          color: "black",
          width: isMobile ? "auto" : 300,
        }}
      >
        <div
          className="divColumn divSpread"
          style={{ padding: 20, display: "flex", flexDirection: "column" }}
        >
          <div className="divCenterAlign mt-20">
            <img src={img} width={imgSize ?? 50} style={{ borderRadius: 10 }} />
          </div>

          <div className="my-0">
            <h4 style={{ color: "black" }}>{titleComponent.text}</h4>
          </div>

          <div
            className="infoMsg divCenterALign"
            style={{
              fontSize: "small",
              justifyContent: "center",
              display: "flex",
              lineHeight: 1.5,
            }}
          >
            <div
              className="infoMsg divCenterALign"
              style={{
                maxWidth: 200,
              }}
            >
              {desc}
            </div>
          </div>

          <div className="mt-20">
            <a
              href={titleComponent.url}
              target="_blank"
              style={{ color: "var(--exhut-total-green)" }}
            >
              LearnMore
            </a>
          </div>
        </div>
      </Paper>
    </Slide>
  );
}
