import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Alert, Button, Flex, Modal } from "antd";
import { Paper } from "@mui/material";
import { Input } from "antd";
import { useGetProfile } from "../../../api/api.get";
import { useUpdatePhone } from "../../../api/api.update";
import { FormRowCol } from "../../../components/forms/form.row.component";
import { useNotifications2 } from "../../../components/notifications/notification";
import { useAuthContext } from "../../../providers/auth.provider";
import { useLayoutContext } from "../../../providers/layout.provider";
import {
  displayPhoneNumber,
  formatBlankWithDefault,
} from "../../../utils/functions";
import {
  usePostTriggerSMSVerification,
  usePostVerifySMSVerification,
} from "../../../api/api";
import { useMessage } from "../../../components/notifications/message";

export default function PhoneSection({ areaCode }: { areaCode: string }) {
  const { user: userAuth } = useAuthContext();
  const { data, refetch } = useGetProfile();
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (userAuth) {
      refetch();
    }
  }, [userAuth]);

  const user = data;

  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useLayoutContext();
  const { notification, notifySuccess, notifyError } = useNotifications2();
  const {
    notification: messageNotification,
    messageSuccess,
    messageError,
  } = useMessage();

  const { mutateAsync: updatePhoneAsync } = useUpdatePhone();
  const [overwriteMode, setOverwriteMode] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      phone: displayPhoneNumber(user?.phone, user?.countryCode),
      consent: false,
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/^[0-9]+$/, "Please enter only numbers.")
        .required("Please enter phone."),
      consent: Yup.bool().oneOf([true], "Please accept the consent."),
    }),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      // @ts-ignore
      delete values["consent"];
      setIsLoading(true);

      updatePhoneAsync({ ...values, phone: `${areaCode}${values.phone}` })
        .then(() => {
          notifySuccess({
            title: "Success",
            description: "Phone number successfully updated.",
          });
          setIsEditMode(false);
        })
        .catch((err) => {
          if (err.response.status === 409) {
            setOverwriteMode(true);
          } else {
            notifyError("Whoops! Please try again");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <div className="">
      {notification}
      {messageNotification}

      <OverwritePhoneModal
        areaCode={areaCode}
        phone={formik.values.phone}
        open={overwriteMode}
        onClose={() => setOverwriteMode(false)}
        onOK={() => {}}
      />

      <Paper
        elevation={6}
        sx={{
          minWidth: "40%",
          paddingLeft: 3,
          paddingRight: 3,
          marginTop: 5,
          boxShadow: "3px 1px 5px 5px #e6ddd6",
        }}
      >
        <div className="p-10">
          <h3 style={{ color: "darkgrey" }}>Communications</h3>
        </div>

        {!isEditMode && (
          <div className="divRight mx-20">
            <button
              className="buttonAsLink"
              style={{
                fontWeight: "bold",
                color: "var(--exhut-total-green)",
                fontSize: 15,
              }}
              onClick={(e) => {
                e.preventDefault();
                setIsEditMode(!isEditMode);
              }}
              id="edit-phone-btn"
            >
              Edit
            </button>
          </div>
        )}

        <form style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 30 }}>
          <div className={isMobile ? "" : "divSpread"}>
            <FormRowCol label="Phone">
              <div
                className={isMobile ? "divColumn" : "divFlex divAlignItemsOnly"}
              >
                <div className={isMobile ? "my-10" : undefined}>
                  {isEditMode && (
                    <Input
                      type="text"
                      prefix={areaCode}
                      className="bordered"
                      maxLength={10}
                      {...formik.getFieldProps("phone")}
                    />
                  )}
                  {!isEditMode && (
                    <strong>
                      {formatBlankWithDefault(formik.values.phone, "")}
                    </strong>
                  )}
                </div>
              </div>
              {formik.errors.phone && (
                <span style={{ fontSize: 15 }} className="errorMsg">
                  {formik.errors.phone}
                </span>
              )}

              {isEditMode && (
                <div className="divFlex my-20">
                  <p
                    style={{
                      fontSize: 15,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="mx-0-"
                  >
                    <input
                      id="consent-check"
                      type="checkbox"
                      checked={formik.values.consent}
                      style={{ marginRight: 10 }}
                      onChange={(e) =>
                        formik.setFieldValue("consent", e.target.checked)
                      }
                    />
                    By checking this box, you agree to receive text messages
                    from ExpenseHut and you are solely responsible for any
                    mobile carrier charges.
                  </p>
                </div>
              )}

              {formik.errors.consent && (
                <span style={{ fontSize: 15 }} className="errorMsg">
                  {formik.errors.consent}
                </span>
              )}
            </FormRowCol>
          </div>

          <div className="divSpread">
            {isEditMode && (
              <Flex gap={10}>
                <Button
                  type="primary"
                  loading={isLoading}
                  onClick={formik.submitForm}
                  disabled={Object.keys(formik.errors).length > 0}
                  id="update-phone"
                >
                  {" "}
                  Update
                </Button>

                <Button onClick={() => setIsEditMode(false)}>Cancel</Button>
              </Flex>
            )}
          </div>
        </form>
      </Paper>
    </div>
  );
}

function OverwritePhoneModal({
  open,
  onClose,
  onOK,
  phone,
  areaCode,
}: {
  phone: string;
  areaCode: string;
  open: boolean;
  onClose: () => void;
  onOK: () => void;
}) {
  const [verifyMode, setVerifyMode] = useState<boolean>(false);
  const { messageSuccess, messageError, notification } = useMessage();
  const [code, setCode] = useState("");
  const [error, setError] = useState<string>();

  const { mutateAsync, isLoading: isTriggerSMSLoading } =
    usePostTriggerSMSVerification();

  const {
    mutateAsync: verifyPasscodeAsync,
    isLoading: isVerifyPasscodeLoading,
  } = usePostVerifySMSVerification();

  useEffect(() => {
    setVerifyMode(false);
  }, [open]);

  const handleTriggerSMS = () => {
    setError(undefined);

    mutateAsync({ phone: `${areaCode}${phone}` })
      .then(() => {
        messageSuccess("One time passcode sent successfully.");
        setVerifyMode(true);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setError("You have exceeded the number of attempts");
        } else {
          setError("Whoops! Unable to perform request.");
        }
      });
  };

  const handleVerifySMS = () => {
    setError(undefined);
    verifyPasscodeAsync({ phone: `${areaCode}${phone}`, code: +code })
      .then(() => {
        messageSuccess("One time passcode verified and phone number updated.");
        setVerifyMode(true);
        onClose();
      })
      .catch((err) => {
        setError("Whoops! Invalid passcode. Please try again.");
      });
  };

  const handleClose = () => {
    setVerifyMode(false);
    onClose();
  };

  return (
    <Modal
      title="Phone number in use"
      open={open}
      onOk={onOK}
      onCancel={onClose}
      okText="Confirm"
      cancelText="Cancel"
      okButtonProps={{
        disabled: false,
        loading: false,
      }}
      footer={null}
    >
      {notification}
      <p>
        We found out this phone number is already in use. If this is yours,
        would you like to confirm it through an One Time Passcode (OTP)?
      </p>
      <p>
        <p>
          <Input prefix={areaCode} disabled={true} value={phone} />
        </p>

        {error && <Alert type="error" message={error} />}

        {!verifyMode && (
          <Flex>
            <Flex gap={5} className="my-40">
              <Button type="text" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                loading={isTriggerSMSLoading}
                type="primary"
                onClick={handleTriggerSMS}
              >
                Send OTP
              </Button>
            </Flex>
          </Flex>
        )}

        {verifyMode && (
          <Flex vertical className="my-40">
            <Input
              placeholder="Enter OTP..."
              id="verify-otp-input"
              onChange={(e) => setCode(e.target.value)}
              value={code}
            />

            <Flex gap={5} className="my-40">
              <Button type="text" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                loading={isVerifyPasscodeLoading}
                type="primary"
                disabled={!code}
                onClick={handleVerifySMS}
              >
                Verify
              </Button>
            </Flex>
          </Flex>
        )}
      </p>
    </Modal>
  );
}
